import React, {Suspense} from 'react'
import {
	createBrowserRouter,
	RouterProvider,
	Navigate
} from "react-router-dom";
import { ProvideAuth } from "./Auth.js";
import Feedback from "./Feedback/Feedback";
import AdminApp from "./Admin/AdminApp";
import ErrorPage from "./ErrorPage"
import Feedbacks from "./Admin/Feedbacks"
import Users from "./Admin/Users"
import Reseller from "./Admin/Reseller"
import Resellers from "./Admin/Resellers"
import Settings from "./Admin/Settings"
import Billing from "./Admin/Billing"
import Signin from "./Admin/Signin"
import ForgotPass from "./Admin/ForgotPass"
import ActivateUser from "./Admin/ActivateUser"
import ResetPassword from "./Admin/ResetPassword"
import { useAuth } from "./Auth.js";

const LogoMockup = () => {
	return (
	<div className="logo-container vw-100 vh-100 d-flex align-items-center justify-content-center">
		<img src='/public/sp_logo.png' style={{maxWidth: 400}}/>
	</div>);
}

const PrivateRoute = ({ children }) => {
	const auth = useAuth();
	const token = localStorage.getItem('t');
	//console.log(token)
	if (token || (auth && auth.user)) {
		return children
	}
	return <Navigate to="/signin" />
}

const router = createBrowserRouter([
	{
		path: "/",
		element: <LogoMockup />,
		errorElement: <ErrorPage />
	},
	{
		path: "/:feedbackid",
		element: <Feedback />,
	},
	{
		path: "/signin",
		element: <Signin />,
	},
	{
		path: "/forgotpass",
		element: <ForgotPass />,
	},
	{
		path: "/resetpassword",
		element: <ResetPassword />,
	},
	{
		path: "/activate",
		element: <ActivateUser />,
	},
	{
		path: "/admin",
		element: 
			<PrivateRoute>
				<AdminApp />
			</PrivateRoute>
		,
		children: [
			{
				path: "/admin/feedbacks",
				element: <PrivateRoute><Feedbacks /></PrivateRoute>,
			},
			{
				path: "/admin/settings",
				element: <PrivateRoute><Settings /></PrivateRoute>,
			},
			{
				path: "/admin/users",
				element: <PrivateRoute><Users /></PrivateRoute>,
			},
			{
				path: "/admin/reseller",
				element: <PrivateRoute><Reseller /></PrivateRoute>,
			},
			{
				path: "/admin/resellers",
				element: <PrivateRoute><Resellers /></PrivateRoute>,
			},
			{
				path: "/admin/billing",
				element: <PrivateRoute><Billing /></PrivateRoute>,
			},
		]
	},
]);

const App = () => {

	return (
		<Suspense fallback={<LogoMockup />}>
			<ProvideAuth>
				<RouterProvider router={router} />
			</ProvideAuth>
		</Suspense>
   );
}

export default App;

