import React, {useEffect} from 'react'
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from "../Auth.js";
import axios from 'axios';
import {API} from '../Configuration';
import LanguageChooser from '../Utils/LanguageChooser';

const Signin = () => {
    const { t, i18n } = useTranslation();

    const auth = useAuth();
    const navigate = useNavigate();

    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [formValid, setFormValid] = React.useState(true);
    const [error, setError] = React.useState(false);

    const handleChange = (event) => {
        if (event.target.id=='email') {
            setEmail(event.target.value)
        } else if (event.target.id=='password') {
            setPassword(event.target.value)
        }
    }

    const handleLogin = (email, password) => {
        setError(false)
        let isValid = true;
        if (email=='' ||
        password=='') {
            isValid = false;
        }
        if (isValid) {
            axios.post(API + '/auth/signin', {
                email: email,
                password: password,
                language: i18n.language
            })
            .then(function (response) {
                if (response.data.success) {
                    auth.setAuthUser(response.data.user);
                    localStorage.setItem('t', response.data.token);
                    if (response.data.user.userlang) {
                        i18n.changeLanguage(response.data.user.userlang);
                    }
                } else {
                    setError(true)
                }
            })
            .catch(function (error) {
                console.error(error)
            });
        } else {
            setFormValid(false)
        }
    }

    useEffect(() => {
		if (auth.user) {
            navigate("/admin/feedbacks")
		}
	}, [auth.user])

	return (
	<div id="adminapp" className="d-flex h-100 align-items-center justify-content-center">

        <div className="col-11 col-md-8" style={{maxWidth: 600}}>
            <div className="card">
                <div className="card-body">
                    <img className="mx-auto mt-2" src='/public/sp_logo_200.png' style={{width: 200}}/>
                    <form className="p-4">
                        {error &&
                            <div className="mb-3">
                                <div className="alert alert-warning" role="alert">
                                    {t('signin.usernotfound')} <br/>
                                </div>
                            </div>
                        }
                        <div className="mb-3">
                            <label htmlFor="email" className="form-label">{t('signin.email')}</label>
                            <input id="email" type="email" className={(!formValid && email=='') ? "form-control is-invalid" : "form-control"} value={email} onChange={handleChange} />
                        </div>
                        <div className="mb-4">
                            <label htmlFor="password" className="form-label">{t('signin.password')}</label>
                            <input id="password" type="password" className={(!formValid && password=='') ? "form-control is-invalid" : "form-control"} value={password}  onChange={handleChange} />
                        </div>
                        <div className="d-flex justify-content-between">
                            <button type="button" className="btn btn-primary" onClick={() => handleLogin(email, password)}>{t('signin.signin')}</button>
                            <Link to="/forgotpass" className="btn btn-link">{t('signin.forgotpass')}</Link>
                        </div>
                    </form>
                </div>
            </div>
            <div className='text-muted text-end pt-1'>
                <LanguageChooser />
            </div>
        </div>

	</div>);
}

export default Signin;