import React from 'react'
import { useTranslation } from "react-i18next";

const Pagination = ({ nPages, currentPage, setCurrentPage }) => {

    const { t, i18n } = useTranslation();

    const pageNumbers = [...Array(nPages + 1).keys()].slice(1)
    const nextPage = () => {
        if(currentPage !== nPages) setCurrentPage(currentPage + 1)
    }
    const prevPage = () => {
        if(currentPage !== 1) setCurrentPage(currentPage - 1)
    }
    return (
        <>
            {nPages>1 &&
                <nav>
                    <ul className='pagination justify-content-center'>
                        <li className="page-item">
                            <a className="page-link" 
                                onClick={prevPage} 
                                href='#'>
                                <span aria-hidden="true">&laquo;</span>
                            </a>
                        </li>
                        {pageNumbers.map(pgNumber => (
                            <li key={pgNumber} 
                                className= {`page-item ${currentPage == pgNumber ? 'active' : ''} `} >
                                <a onClick={() => setCurrentPage(pgNumber)}  
                                    className='page-link' 
                                    href='#'>
                                    {pgNumber}
                                </a>
                            </li>
                        ))}
                        <li className="page-item">
                            <a className="page-link" 
                                onClick={nextPage}
                                href='#'>
                                <span aria-hidden="true">&raquo;</span>
                            </a>
                        </li>
                    </ul>
                </nav>
            }
        
        </>
        
    )
}

export default Pagination