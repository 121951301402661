import React, {useEffect} from 'react'
import axios from 'axios';
import { useTranslation } from "react-i18next";
import {API} from '../../Configuration';
import { useAuth } from "../../Auth.js";
import BaseCircleChart from '../../Utils/BaseCircleChart';

const LastMonth = (props) => {
    const { t } = useTranslation();

    const auth = useAuth();
    const token = localStorage.getItem('t');

    const [stats, setStats] = React.useState({});

    useEffect(() => {
		if (props.feedback.id) {
            axios.get(API + '/feedbacks/stats/lastmonth?id='+props.feedback.id, {headers: { Authorization: `Bearer ${token}`} })
            .then(function (response) {
                if (response.data) {
                    setStats(response.data)
                }
            })
            .catch(function(error) {
            });
		}
	}, [props.feedback])


	return (
        <div className="feedback-stats-container mt-4 pb-4 border-bottom">
            <div className="row">
                <div className="col-12">
                    <h4>{t('stats.lastmonth-title')}</h4>
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-12">
                    <BaseCircleChart item="month" stats={stats} titletype="big" title={t('stats.lastmonth-title')} width="200px" height="200px" />
                </div>
            </div>
        </div>
    );
}

export default LastMonth;