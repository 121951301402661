import React, {useEffect} from 'react'
import axios from 'axios';
import moment from 'moment';
import { useTranslation } from "react-i18next";
import {API} from '../Configuration';
import { useAuth } from "../Auth.js";

const Billing = () => {
    const { t, i18n } = useTranslation();

    const auth = useAuth();
    const token = localStorage.getItem('t');

    // set locale for moment
    moment.locale(i18n.language);

    const [billings, setBillings] = React.useState(null);
    const [selectedMonth, setSelectedMonth] = React.useState('all');

    const loadBillings = () => {
        setBillings(null)
        axios.get(API + '/users/billing?month='+selectedMonth , {headers: { Authorization: `Bearer ${token}`} })
        .then(function (response) {
            if (response.data) {
                setBillings(response.data.billings)
            }
        })
        .catch(function(error) {
        });
    }

    const handleMonthChange = (event) => {
        setSelectedMonth(event.target.value);
    }

    const AnnualPrice = (billing) => {
        let monthlyint = parseInt(billing.price)
        let annual = monthlyint * 12;
        return <>{annual}</>;
    }

    useEffect(() => {
        loadBillings();
	}, [selectedMonth])

	return (
        <div className="container">

            {(auth.user && auth.user.og) &&
            <div className="row gx-5">
                <div className="col-12">
                    <div className="mb-4">
                        <label htmlFor="user" className="form-label">{t('users.billingmonth')}</label>
                        <select id="user" className="form-select" name="billingmonth" value={selectedMonth} onChange={handleMonthChange}>
                            <option value="" default disabled hidden>{t('users.billingmonth-default')}</option>
                            <option value="all">{t('adminapp.all')}</option>
                            <option value="1">{moment().month(0).format('MMMM')}</option>
                            <option value="2">{moment().month(1).format('MMMM')}</option>
                            <option value="3">{moment().month(2).format('MMMM')}</option>
                            <option value="4">{moment().month(3).format('MMMM')}</option>
                            <option value="5">{moment().month(4).format('MMMM')}</option>
                            <option value="6">{moment().month(5).format('MMMM')}</option>
                            <option value="7">{moment().month(6).format('MMMM')}</option>
                            <option value="8">{moment().month(7).format('MMMM')}</option>
                            <option value="9">{moment().month(8).format('MMMM')}</option>
                            <option value="10">{moment().month(9).format('MMMM')}</option>
                            <option value="11">{moment().month(10).format('MMMM')}</option>
                            <option value="12">{moment().month(11).format('MMMM')}</option>
                        </select>
                    </div>

                </div>
            </div>
            }

            <div className="row gx-5">
                <div className="col-12">
                    <h2>{t('adminapp.billing-title')} - {(selectedMonth=='all') ? t('adminapp.all') : moment().month(parseInt(selectedMonth)-1).format('MMMM')}</h2>
                    {billings &&
                    <>

                    <div className="row py-2 mb-1 border-bottom">
                        <div className="col-4"><strong>{t('adminapp.billing-name')}</strong></div>
                        <div className="col-4"><strong>{t('adminapp.billing-payment')}</strong></div>
                        <div className="col-4 text-end"><strong>{t('adminapp.billing-price')}</strong></div>
                    </div>


                    {billings.map((billing) =>
                        <div key={billing.id} className="row py-2 border-bottom">
                            <div className="col-4">{billing.firstname} {billing.lastname} {billing.company ? ' / '+billing.company : ''}<br/><small>{t('users.license-'+billing.license)}</small></div>
                            <div className="col-4">
                                {t('users.payment-'+billing.paymenttype)}<br/>
                                {(billing.paymenttype=='invoice') &&
                                    <small>{billing.address}, {billing.zip} {billing.city}</small>
                                }
                                {(billing.paymenttype=='einvoice') &&
                                    <small>{billing.einvoicingaddress} / {billing.einvoicingbrokerid}</small>
                                }
                                {(billing.paymenttype=='email') &&
                                    <small>{billing.email}</small>
                                }
                            </div>
                            <div className="col-4 text-end">
                                {(billing.price)
                                ? <><AnnualPrice {...billing} />&euro;<br/><small>{billing.price}&euro; / kk</small></>
                                :
                                    <>
                                        {(billing.license=='onebyone') &&
                                            <>120&euro;<br/><small>10&euro; / kk</small></>
                                        }
                                        {(billing.license=='5') &&
                                            <>360&euro;<br/><small>30&euro; / kk</small></>
                                        }
                                        {(billing.license=='10') &&
                                            <>600&euro;<br/><small>50&euro; / kk</small></>
                                        }
                                        {(billing.license=='100') &&
                                            <>1 200&euro;<br/><small>100&euro; / kk</small></>
                                        }
                                        {(billing.license=='custom') &&
                                            <>?&euro;<br/><small>?&euro; / kk</small></>
                                        }
                                    </>
                                }
                            </div>
                        </div>
                    )}
                    </>
                    }
                    <p></p>
                </div>
            </div>
        </div>
    );
}

export default Billing;