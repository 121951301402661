import React, {useEffect, useState} from 'react'
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from "react-i18next";
import {API} from '../Configuration';
import moment from 'moment';


const UserForm = (props) => {
    const { t, i18n } = useTranslation();

    const token = localStorage.getItem('t');

    // set locale for moment
    moment.locale(i18n.language);

    const [user, setUser] = React.useState(null);
    const [formModified, setFormModified] = React.useState(false);
    const [formValid, setFormValid] = React.useState(true);
    const [saved, setSaved] = React.useState(false);
    const [activationSended, setActivationSended] = React.useState(false);
    const [activationEmail, setActivationEmail] = React.useState('');

    const handleActicationEmailChange = (event) => {
        let value = event.target.value;
        setActivationEmail(value)
    }

    const handleChange = (event) => {
        setFormModified(true)
        let field = (event.target.type === 'radio') ? event.target.name : event.target.id;
        let value = (event.target.type === 'checkbox') ? event.target.checked : event.target.value;
        setUser({
			...user,
			[field]: value
		});
    }

    const handleWhisleblowChange = (event) => {
        setFormModified(true)
        let value = event.target.value;
        let boolValue = false;
        if (value=="true") {
            boolValue = true;
        }
        setUser({
			...user,
			["whistleblow"]: boolValue
		});
    }

    const saveUser = () => {
        setSaved(false)
        let isValid = true;
        if (user.firstname=="" ||
            user.lastname=="" ||
            user.email=="" ||
            user.address=="" ||
            user.city=="" ||
            user.zip==""  
            ) {
            isValid = false;
        }
        if (isValid) {
            // post user data to backend
            setFormValid(true)

            axios.post(API + '/users/save', {
                ...user,
                language: i18n.language
            }, {headers: { Authorization: `Bearer ${token}`} })
            .then(function (response) {
                if (response.data.success) {
                    setFormValid(true)
                    setFormModified(false)
                    setSaved(true)
                    setUser(response.data.user)
                    if (props.reloadUsers) {
                        props.reloadUsers()
                    }
				}
            })
            .catch(function (error) {
            });
        } else {
            setFormValid(false)
        }
    }

    const clearUser = () => {
        setSaved(false)
        setFormModified(false)
        setUser(null)
        if (props.clearUser) {
            props.clearUser()
        }
    }

    const deleteUser = () => {
        if (confirm(t('users.confirmdelete'))) {
            axios.get(API + '/users/delete?id=' + user.id, {headers: { Authorization: `Bearer ${token}`} })
            .then(function (response) {
                if (response.data.success) {
                    setFormModified(false)
                    setSaved(false)
                    setUser(null)
                    if (props.reloadUsers) {
                        props.reloadUsers()
                    }
                }
            })
            .catch(function(error) {
            });
        }
    }

    const sendactivation = () => {
        setActivationSended(false)
        if (confirm(t('users.send-activation-confirm'))) {
            axios.get(API + '/users/resendactivation?id=' + user.id + '&email=' + activationEmail, {headers: { Authorization: `Bearer ${token}`} })
            .then(function (response) {
                if (response.data.success) {
                    setActivationSended(true)
                }
            })
            .catch(function(error) {
            });
        }
    }

    // clone user object
    useEffect(() => {
        setActivationSended(false)
        if (!formModified) {
            setFormModified(false)
            setSaved(false)
            setUser(JSON.parse(JSON.stringify(props.user)))
        } else {
            if (confirm(t('users.confirmidmodified'))) {
                setFormModified(false)
                setSaved(false)
                setUser(JSON.parse(JSON.stringify(props.user)))
            }
        }
	}, [props.user])

	return (
        <div className="row">
            <div className="col-12">
                {user &&
                    <form className="pb-4">

                        <div className="d-flex justify-content-between">
                            {user.id 
                            ? <h3 dangerouslySetInnerHTML={{__html: t('users.title-existing', { user: user }) }} />
                            : <h3>{t('users.title-new')}</h3>
                            }
                            {user.id &&
                            <button type="button" className="btn btn-outline-danger btn-sm ms-auto" onClick={deleteUser}>{t('users.delete')}</button>
                            }
                        </div>

                        <div className="mt-3 mb-3 alert alert-warning" role="alert">
                            <div className="row">
                                <div className="col-md-12">
                                    {user.activationDate &&
                                        <p>{t('users.activation-date')} {moment(user.activationDate).format(t('dateformat-long'))}</p>
                                    }
                                    {activationSended 
                                    ? <p className="text-start">{t('users.send-activation-done')}</p>
                                    : <>
                                        <div className="input-group">
                                            <input type="text" className="form-control" placeholder={t('users.send-activation-email')} value={activationEmail} onChange={handleActicationEmailChange}/>
                                            <button type="button" className="btn btn-primary btn-sm" onClick={sendactivation}>{t('users.send-activation')}</button>
                                        </div>
                                    </>
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="mt-3 mb-3">
                            <label htmlFor="firstname" className="form-label">{t('users.firstname')} *</label>
                            <input id="firstname" type="text" name="firstname" className={(!formValid && user.firstname=="" ) ? "form-control is-invalid" : "form-control"} value={user.firstname} onChange={handleChange}/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="lastname" className="form-label">{t('users.lastname')} *</label>
                            <input id="lastname" type="text" name="lastname" className={(!formValid && user.lastname=="" ) ? "form-control is-invalid" : "form-control"} value={user.lastname} onChange={handleChange}/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="company" className="form-label">{t('users.company')}</label>
                            <input id="company" type="text" name="company" className="form-control" value={user.company} onChange={handleChange}/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="reknro" className="form-label">{t('users.reknro')}</label>
                            <input id="reknro" type="text" name="reknro" className="form-control" value={user.reknro} onChange={handleChange}/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="email" className="form-label">{t('users.email')} *</label>
                            <input id="email" type="email" name="email" className={(!formValid && user.email=="" ) ? "form-control is-invalid" : "form-control"} value={user.email} onChange={handleChange}/>
                        </div>

                        <div className="mt-3 mb-3">
                            <label htmlFor="address" className="form-label">{t('users.address')} *</label>
                            <input id="address" type="text" name="address" className={(!formValid && user.address=="" ) ? "form-control is-invalid" : "form-control"} value={user.address} onChange={handleChange}/>
                        </div>

                        <div className="row">
                            <div className="col-8">
                                <div className="mb-3">
                                    <label htmlFor="city" className="form-label">{t('users.city')} *</label>
                                    <input id="city" type="text" name="city" className={(!formValid && user.city=="" ) ? "form-control is-invalid" : "form-control"} value={user.city} onChange={handleChange}/>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="mb-3">
                                    <label htmlFor="zip" className="form-label">{t('users.zip')} *</label>
                                    <input id="zip" type="text" name="zip" className={(!formValid && user.zip=="" ) ? "form-control is-invalid" : "form-control"}value={user.zip} onChange={handleChange}/>
                                </div>
                            </div>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="userlang" className="form-label">{t('users.userlang')} *</label>
                            <select id="userlang" className="form-select" name="userlang" value={user.userlang} onChange={handleChange}>
                                <option value="en">{t('lang.en')}</option>
                                <option value="fi">{t('lang.fi')}</option>
                                <option value="no">{t('lang.no')}</option>
                            </select>
                        </div>

                        <div className="mt-3 mb-3 alert alert-warning" role="alert">
                            <div className="mb-3">
                                <label htmlFor="license" className="form-label">{t('users.license')} *</label>
                                <select id="license" className="form-select" name="license" value={user.license} onChange={handleChange}>
                                    <option value="onebyone">{t('users.license-onebyone')}</option>
                                    <option value="5">{t('users.license-5')}</option>
                                    <option value="10">{t('users.license-10')}</option>
                                    <option value="100">{t('users.license-100')}</option>
                                    <option value="custom">{t('users.license-custom')}</option>
                                </select>
                            </div>
                            
                            <div className="mb-3">
                                <label htmlFor="price" className="form-label">{t('users.license-whistleblow')}</label>
                                <div className="form-check">
                                    <input id="whistleblow-false" className="form-check-input" type="radio" name="whistleblow" value="false" checked={user.whistleblow ? false : true} onChange={handleWhisleblowChange}/>
                                    <label className="form-check-label" htmlFor="whistleblow-false">{t('no')}</label>
                                </div>
                                <div className="form-check">
                                    <input id="whistleblow-true" className="form-check-input" type="radio" name="whistleblow" value="true" checked={user.whistleblow ? true : false} onChange={handleWhisleblowChange}/>
                                    <label className="form-check-label" htmlFor="whistleblow-true">{t('yes')}</label>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-6">
                                    <div className="mb-3">
                                        <label htmlFor="price" className="form-label">{t('users.price')}</label>
                                        <input id="price" type="text" name="price" className="form-control" value={user.price} onChange={handleChange}/>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="mb-3">
                                        <label htmlFor="billingmonth" className="form-label">{t('users.billingmonth')} *</label>
                                        <select id="billingmonth" className="form-select" name="billingmonth" value={user.billingmonth} onChange={handleChange}>
                                            <option value="" default disabled hidden>{t('users.billingmonth-default')}</option>
                                            <option value="1">{moment().month(0).format('MMMM')}</option>
                                            <option value="2">{moment().month(1).format('MMMM')}</option>
                                            <option value="3">{moment().month(2).format('MMMM')}</option>
                                            <option value="4">{moment().month(3).format('MMMM')}</option>
                                            <option value="5">{moment().month(4).format('MMMM')}</option>
                                            <option value="6">{moment().month(5).format('MMMM')}</option>
                                            <option value="7">{moment().month(6).format('MMMM')}</option>
                                            <option value="8">{moment().month(7).format('MMMM')}</option>
                                            <option value="9">{moment().month(8).format('MMMM')}</option>
                                            <option value="10">{moment().month(9).format('MMMM')}</option>
                                            <option value="11">{moment().month(10).format('MMMM')}</option>
                                            <option value="12">{moment().month(11).format('MMMM')}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <h4>{t('users.payment-type')}</h4>

                        <div className="form-check">
                            <input id="paymentinvoice" className="form-check-input" type="radio" name="paymenttype" value="invoice" checked={user.paymenttype=='invoice' ? true : false} onChange={handleChange}/>
                            <label className="form-check-label" htmlFor="paymentinvoice">{t('users.payment-invoice')}</label>
                        </div>
                        <div className="form-check">
                            <input id="paymenteinvoice" className="form-check-input" type="radio" name="paymenttype" value="einvoice" checked={user.paymenttype=='einvoice' ? true : false} onChange={handleChange}/>
                            <label className="form-check-label" htmlFor="paymenteinvoice">{t('users.payment-einvoice')}</label>
                        </div>
                        <div className="form-check">
                            <input id="paymentemail" className="form-check-input" type="radio" name="paymenttype" value="email" checked={user.paymenttype=='email' ? true : false} onChange={handleChange}/>
                            <label className="form-check-label" htmlFor="paymentemail">{t('users.payment-email')}</label>
                        </div>


                        <div className="mt-3 mb-3">
                            <label htmlFor="einvoicingaddress" className="form-label">{t('users.einvoicing-address')}</label>
                            <input id="einvoicingaddress" type="text" name="einvoicingaddress" className="form-control" value={user.einvoicingaddress} onChange={handleChange}/>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="einvoicingbrokerid" className="form-label">{t('users.einvoicing-broker-id')}</label>
                            <input id="einvoicingbrokerid" type="text" name="einvoicingbrokerid" className="form-control" value={user.einvoicingbrokerid} onChange={handleChange}/>
                        </div>

                        <div className="mt-3 mb-3 alert alert-warning" role="alert">
                            <div className="mb-3">
                                <label htmlFor="ordernumber" className="form-label">{t('users.ordernumber')}</label>
                                <input id="ordernumber" type="text" name="ordernumber" className="form-control" value={user.ordernumber} onChange={handleChange}/>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="notes" className="form-label">{t('users.notes')}</label>
                                <textarea id="notes" name="notes" rows="6" className="form-control" value={user.notes} onChange={handleChange}/>
                            </div>
                        </div>
                        {saved &&
                        <div className="mt-3 alert alert-success" role="alert">{t('users.saved')}</div>
                        }

                        <div className="d-flex justify-content-between mt-5">
                            <button type="button" className="btn btn-primary" onClick={saveUser}>{t('users.save')}</button>
                            <button type="button" className="btn btn-link ms-auto" onClick={clearUser}>{t('users.cancel')}</button>
                        </div>
                    </form>

                }
            </div>
        </div>
	);
}

export default UserForm;