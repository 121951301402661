import React, {useEffect, useState} from 'react'
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from "react-i18next";
import {API} from '../Configuration';
import moment from 'moment';

const ResellerForm = (props) => {
    const { t, i18n } = useTranslation();

    const token = localStorage.getItem('t');

    // set locale for moment
    moment.locale(i18n.language);

    const [reseller, setReseller] = React.useState(null);
    const [newuser, setNewuser] = React.useState({
        name: "",
        email: "",
        code: ""
    });
    const [formValid, setFormValid] = React.useState(true);
    const [adduserformValid, setAdduserFormValid] = React.useState(true);
    const [saved, setSaved] = React.useState(false);

    const handleChange = (event) => {
        let field = (event.target.type === 'radio') ? event.target.name : event.target.id;
        let value = (event.target.type === 'checkbox') ? event.target.checked : event.target.value;
        setReseller({
			...reseller,
			[field]: value
		});
    }


    const handleUserChange= (event) => {
        let field = (event.target.type === 'radio') ? event.target.name : event.target.id;
        let value = (event.target.type === 'checkbox') ? event.target.checked : event.target.value;
        setNewuser({
			...newuser,
			[field]: value
		});
    }

    const saveReseller = () => {
        setSaved(false)
        let isValid = true;
        if (reseller.company=="" ||
            reseller.reknro==""
            ) {
            isValid = false;
        }
        if (isValid) {
            // post reseller data to backend
            setFormValid(true)

            axios.post(API + '/resellers/save', {
                ...reseller,
                language: i18n.language
            }, {headers: { Authorization: `Bearer ${token}`} })
            .then(function (response) {
                if (response.data.success) {
                    setFormValid(true)
                    setSaved(true)
                    setReseller(response.data.reseller)
                    if (props.reloadResellers) {
                        props.reloadResellers()
                    }
				}
            })
            .catch(function (error) {
            });
        } else {
            setFormValid(false)
        }
    }

    const clearReseller = () => {
        setSaved(false)
        setFormModified(false)
        setReseller(null)
        if (props.clearReseller) {
            props.clearReseller()
        }
    }

    const deleteReseller = () => {
        if (confirm(t('resellers.confirmdelete'))) {
            axios.get(API + '/resellers/delete?id=' + reseller.id, {headers: { Authorization: `Bearer ${token}`} })
            .then(function (response) {
                if (response.data.success) {
                    setSaved(false)
                    setReseller(null)
                    if (props.reloadResellers) {
                        props.reloadResellers()
                    }
                }
            })
            .catch(function(error) {
            });
        }
    }

    const addUser = () => {
        let isValid = true;
        if (newuser.name=="" || newuser.email=="") {
            isValid = false;
        }
        if (isValid) {
            // post reseller data to backend
            setAdduserFormValid(true)

            axios.post(API + '/resellers/adduser', {
                ...newuser,
                id: reseller.id,
                language: i18n.language
            }, {headers: { Authorization: `Bearer ${token}`} })
            .then(function (response) {
                if (response.data.success) {
                    setAdduserFormValid(true)
                    setNewuser({
                        name: "",
                        email: ""
                    })

                    setReseller({
                        ...reseller,
                        users: response.data.users
                    });
				}
            })
            .catch(function (error) {
            });
        } else {
            setAdduserFormValid(false)
        }

    }

    const deleteUser = (user) => {
        if (confirm(t('resellers.user.confirmdelete'))) {
            axios.get(API + '/resellers/deleteuser?id=' + reseller.id + '&uid=' + user.code, {headers: { Authorization: `Bearer ${token}`} })
            .then(function (response) {
                if (response.data.success) {
                    setReseller({
                        ...reseller,
                        users: response.data.users
                    });
                }
            })
            .catch(function(error) {
            });
        }
    }

    // clone reseller object
    useEffect(() => {
        setSaved(false)
        setReseller(JSON.parse(JSON.stringify(props.reseller)))
	}, [props.reseller])

	return (
        <div className="row">
            <div className="col-12">
                {reseller &&
                    <form className="pb-4">

                        <div className="d-flex justify-content-between">
                            {reseller.id 
                            ? <h3 dangerouslySetInnerHTML={{__html: t('resellers.title-existing', { reseller: reseller }) }} />
                            : <h3>{t('resellers.title-new')}</h3>
                            }
                            {reseller.id &&
                            <button type="button" className="btn btn-outline-danger btn-sm ms-auto" onClick={deleteReseller}>{t('resellers.delete')}</button>
                            }
                        </div>

                        <div className="mb-3">
                            <label htmlFor="company" className="form-label">{t('resellers.company')}</label>
                            <input id="company" type="text" name="company" className={(!formValid && reseller.company=="" ) ? "form-control is-invalid" : "form-control"} value={reseller.company} onChange={handleChange}/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="reknro" className="form-label">{t('resellers.reknro')}</label>
                            <input id="reknro" type="text" name="reknro" className={(!formValid && reseller.reknro=="" ) ? "form-control is-invalid" : "form-control"} value={reseller.reknro} onChange={handleChange}/>
                        </div>

                        <hr/>

                        <div className="row">
                            <div className="col-12">
                                <h5>{t('resellers.users-title')}</h5>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                {reseller.users &&
                                    <>
                                        {reseller.users.map((item, index) =>
                                            <div key={item.email + '_' + index} className="row mb-1">
                                                <div className="col-5">{item.name}</div>
                                                <div className="col-5">{item.email}</div>
                                                <div className="col-2"><button type="button" className="btn btn-outline-danger btn-sm" onClick={() => deleteUser(item)}>{t('resellers.user.delete')}</button></div>
                                            </div>
                                        )}
                                    </>
                                }
                            </div>
                        </div>

                        {reseller.id &&
                        <>
                        <div className="row mt-4">
                            <div className="col-12">
                                <p>{t('resellers.adduser-title')}</p>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-5">
                                <div className="mb-3">
                                    <label htmlFor="name" className="form-label">{t('resellers.user.name')} *</label>
                                    <input id="name" type="text" name="name" className={(!adduserformValid && newuser.name=="" ) ? "form-control is-invalid" : "form-control"} value={newuser.name} onChange={handleUserChange}/>
                                </div>
                            </div>
                            <div className="col-5">
                                <div className="mb-3">
                                    <label htmlFor="email" className="form-label">{t('resellers.user.email')} *</label>
                                    <input id="email" type="text" name="email" className={(!adduserformValid && newuser.email=="" ) ? "form-control is-invalid" : "form-control"} value={newuser.email} onChange={handleUserChange}/>
                                </div>
                            </div>
                            <div className="col-2">
                                <div className="mb-3">
                                    <label htmlFor="adduser" className="form-label">&nbsp;</label>
                                    <button type="button" className="btn btn-primary d-block" onClick={addUser}>{t('resellers.user.add')}</button>
                                </div>
                            </div>
                        </div>
                        </>
                        }   
                       

                        {saved &&
                        <div className="mt-3 alert alert-success" role="alert">{t('resellers.saved')}</div>
                        }

                        <div className="d-flex justify-content-between mt-5">
                            <button type="button" className="btn btn-primary" onClick={saveReseller}>{t('resellers.save')}</button>
                            <button type="button" className="btn btn-link ms-auto" onClick={clearReseller}>{t('resellers.cancel')}</button>
                        </div>
                    </form>

                }
            </div>
        </div>
	);
}

export default ResellerForm;