import React, {useEffect, useState} from 'react'
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from "react-i18next";
import {API} from '../Configuration';
import UserForm from "./UserForm"
import PaginationList from '../Utils/PaginationList';

const defaultUser = {
    firstname: "", 
    lastname: "", 
    company: "", 
    reknro: "", 
    email: "", 
    userlang: "fi",
    license: "onebyone", 
    price: "",
    billingmonth: "",
    paymenttype: "invoice", 
    address: "", 
    city: "", 
    zip: "", 
    einvoicingaddress: "", 
    einvoicingbrokerid: "",
    ordernumber: "",
    notes: "",
    reseller: ""
}

const Users = () => {
    const { t, i18n } = useTranslation();

    const token = localStorage.getItem('t');

    const [selectedUser, setSelectedUser] = React.useState(null);
    const [users, setUsers] = React.useState(null);

    const selectUser = (user) => {
        setSelectedUser(user);
    }

    const clearUser = () => {
        setSelectedUser(null);
    }

    const addUser = () => {
        setSelectedUser(defaultUser);
    }

    const loadUsers = () => {
        setUsers(null)
        axios.get(API + '/users/list', {headers: { Authorization: `Bearer ${token}`} })
        .then(function (response) {
            if (response.data) {
                setUsers(response.data.users)
            }
        })
        .catch(function(error) {
        });
    }

    const displayUser = (item, props) => {
        return (
        <button className={(selectedUser && selectedUser.id==item.id) ? "list-group-item list-group-item-action active" : "list-group-item list-group-item-action"} onClick={() => selectUser(item)}>
            <div className="ms-2 me-auto">
            <div className="fw-bold">{item.firstname} {item.lastname} {(item.company) ? " / " + item.company : ""}</div>
                {item.license=='onebyone' &&
                    <>{t('users.license-onebyone')}</>
                }    
                {item.license=='10' &&
                    <>{t('users.license-10')}</>
                }   
                {item.license=='100' &&
                    <>{t('users.license-100')}</>
                }    
                {item.license=='custom' &&
                    <>{t('users.license-custom')}</>
                }                 
                
            </div>
        </button>
        );
    }

    useEffect(() => {
		if (!users) {
            loadUsers();
		}
	}, [])

	return (
	<div className="container">
        <div className="row gx-5">
            <div className="col-12 col-md-3">
                <div className="d-grid gap-2 mb-4">
                    <button className="btn btn-primary" onClick={addUser}>{t('users.add')}</button>
                </div>
                {users &&
                    <PaginationList data={users} limit={10} search={true} itemrender={displayUser} itemaction={displayUser} searchkeys={['firstname', 'lastname', 'company']}/>
                }
            </div>
            <div className="col-12 col-md-9 pt-3 pt-md-0">
                <UserForm user={selectedUser} reloadUsers={loadUsers} clearUser={clearUser} />
            </div>
        </div>
	</div>
    );
}

export default Users;