import React, {useEffect} from 'react'
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import { useAuth } from "../Auth.js";
import LanguageChooser from '../Utils/LanguageChooser';

const ForgotPass = () => {
    const { t, i18n } = useTranslation();

    const auth = useAuth();

    const [email, setEmail] = React.useState('');
    const [emailSended, setEmailSended] = React.useState(false);
    const [formValid, setFormValid] = React.useState(true);

    const handleChange = (event) => {
        setEmail(event.target.value)
    }

    const sendMail = () => {

        let isValid = true;
        if (email=='') {
            isValid = false;
        }
        if (isValid) {
            auth.sendPasswordResetEmail(email);
            setEmailSended(true)
            setFormValid(true)
        } else {
            setFormValid(false)
        }
        
    }

	return (
	<div id="adminapp" className="d-flex h-100 align-items-center justify-content-center">

        <div className="col-11 col-md-8" style={{maxWidth: 600}}>
            <div className="card">
                <div className="card-body">
                    <img className="mx-auto mt-2" src='/public/sp_logo_200.png' style={{width: 200}}/>
                    <form className="p-4">
                        {!emailSended 
                        ? (
                            <>
                            <div className="mb-3">
                                <p>{t('signin.forgotpassinfo')}</p>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="email" className="form-label">{t('signin.email')}</label>
                                <input id="email" type="email" className={(!formValid ) ? "form-control is-invalid" : "form-control"} value={email}  onChange={handleChange} />
                            </div>
                            <div className="d-flex justify-content-between">
                                <button type="button" className="btn btn-primary" onClick={() => sendMail(email)}>{t('signin.sendresetemail')}</button>
                                <Link to="/signin" className="btn btn-link">{t('signin.signin')}</Link>
                            </div>
                            </>
                        )
                        : (
                            <div className="mb-3">
                                <p>{t('signin.resetemailsent')}</p>
                            </div>
                        )}
                    </form>
                </div>
            </div>
            <div className='text-muted text-end pt-1'>
                <LanguageChooser />
            </div>
        </div>

	</div>);
}

export default ForgotPass;