import React, {useEffect} from 'react'
import { useSearchParams, useNavigate, Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useAuth } from "../Auth.js";
import axios from 'axios';
import {API} from '../Configuration';

const ResetPassword = () => {
    const { t } = useTranslation();

    const auth = useAuth();
    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();
    const code = searchParams.get("code")

    const [password, setPassword] = React.useState('');
    const [password2, setPassword2] = React.useState('');
    const [error, setError] = React.useState(false);
    const [terms, setTerms] = React.useState(false);
    const [formValid, setFormValid] = React.useState(true);
    const [passwordLengthValid, setPasswordLengthValid] = React.useState(true);
    const [passwordSameValid, setPasswordSameValid] = React.useState(true);

    const handleChange = (event) => {
        if (event.target.id=='email') {
            setEmail(event.target.value)
        } else if (event.target.id=='password') {
            setPassword(event.target.value)
        } else if (event.target.id=='password2') {
            setPassword2(event.target.value)
        } else if (event.target.id=='terms') {
            setTerms(event.target.checked)
        }
    }

    const resetPassword = () => {
        let isValid = true;
        if (!code) {
            isValid = false;
        }
        if (password=='' ||
            password2=='') {
            isValid = false;
        }
        if (password!==password2) {
            isValid = false;
            setPasswordSameValid(false)
        } else {
            setPasswordSameValid(true)
        }
        if (password.length<8) {
            isValid = false;
            setPasswordLengthValid(false)
        } else {
            setPasswordLengthValid(true)
        }

        if (isValid) {
            setFormValid(true)
            axios.post(API + '/auth/resetpass', {
                code: code,
                password: password
            })
            .then(function (response) {
                if (response.data.success) {
                    navigate("/signin")
                } else {
                    setError(true)
                }
            })
            .catch(function (error) {
                console.error(error)
                setError(true)
            });
        } else {
            setFormValid(false)
        }
    }

	return (
	<div id="adminapp" className="d-flex h-100 align-items-center justify-content-center">

        <div className="col-11 col-md-8" style={{maxWidth: 600}}>
            <div className="card">
                <div className="card-body">
                    <img className="mx-auto mt-2" src='/public/sp_logo_200.png' style={{width: 200}}/>
                    <form className="p-4">
                        {!error 
                        ? (
                            <>
                            <div className="mb-3">
                                <p>{t('signin.resetpassword-title')}</p>
                            </div>

                            {!passwordLengthValid &&
                                <div className="alert alert-warning" role="alert">{t('signin.resetpassword-length-alert')}</div>
                            }

                            {!passwordSameValid &&
                                <div className="alert alert-warning" role="alert">{t('signin.resetpassword-same-alert')}</div>
                            }

                            <div className="mb-4">
                                <label htmlFor="password" className="form-label">{t('signin.password')}</label>
                                <input id="password" type="password" className={(!formValid && !password || (password!==password2) ) ? "form-control is-invalid" : "form-control"} value={password} onChange={handleChange} />
                            </div>
                            <div className="mb-4">
                                <label htmlFor="password2" className="form-label">{t('signin.password2')}</label>
                                <input id="password2" type="password" className={(!formValid && !password2 || (password!==password2) ) ? "form-control is-invalid" : "form-control"} value={password2} onChange={handleChange} />
                            </div>

                            <div className="d-flex justify-content-start">
                                <button type="button" className="btn btn-primary" onClick={() => resetPassword()}>{t('signin.resetpassword-button')}</button>
                            </div>
                            </>
                        )
                        : (
                            <div className="mb-3">
                                <div className="alert alert-danger" role="alert">
                                    {t('signin.resetpassword-error')} <br/>
                                    <Link to="/forgotpass">{t('signin.resetpassword-link')}</Link>
                                </div>
                            </div>
                        )}
                    </form>
                </div>
            </div>
        </div>

	</div>);
}

export default ResetPassword;