import React, {useEffect} from 'react'
import { useParams } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import { useTranslation } from "react-i18next";
import {API} from '../Configuration';
import { useAuth } from "../Auth.js";


const Settings = () => {
    const { t, i18n } = useTranslation();

    const auth = useAuth();
    const token = localStorage.getItem('t');

    const [user, setUser] = React.useState(null);
    const [formModified, setFormModified] = React.useState(false);
    const [formValid, setFormValid] = React.useState(true);
    const [saved, setSaved] = React.useState(false);

    const handleChange = (event) => {
        setFormModified(true)
        let field = (event.target.type === 'radio') ? event.target.name : event.target.id;
        let value = (event.target.type === 'checkbox') ? event.target.checked : event.target.value;
        setUser({
			...user,
			[field]: value
		});
    }

    const saveUser = () => {
        setSaved(false)
        let isValid = true;
        if (user.firstname2=="" ||
            user.lastname=="" ||
            user.email=="" ||
            user.address=="" ||
            user.city=="" ||
            user.zip==""  
            ) {
            isValid = false;
        }
        if (isValid) {
            // post user data to backend
            setFormValid(true)
            axios.post(API + '/users/save', user, {headers: { Authorization: `Bearer ${token}`} })
            .then(function (response) {
                if (response.data.success) {
                    setFormValid(true)
                    setFormModified(false)
                    setSaved(true)
                    //setUser(response.data.user)
                    auth.setAuthUser(response.data.user);
                    if (user.userlang && i18n.language!==user.userlang) {
                        i18n.changeLanguage(user.userlang);
                    }

				}
            })
            .catch(function (error) {
            });
        } else {
            setFormValid(false)
        }
    }

    useEffect(() => {
		if (auth.user) {
            setUser(auth.user)
		}
	}, [auth.user])

	return (
    <div className="container">
        <div className="row gx-5">
            <div className="col-12 col-md-3">
            {user &&
                <>
                    <div className="mb-3">
                        <h5>{t('users.license')}</h5>
                        <p>{t('users.license-'+user.license)}</p>
                    </div>
                </>
                }
            </div>
            <div className="col-12 col-md-9 pt-3 pt-md-0">
            {user &&
                    <form className="pb-4">

                        <div className="d-flex justify-content-between">
                            <h3>{t('adminapp.account-title')}</h3>
                        </div>

                        <div className="mt-3 mb-3">
                            <label htmlFor="firstname" className="form-label">{t('users.firstname')} *</label>
                            <input id="firstname" type="text" name="firstname" className={(!formValid && user.firstname=="" ) ? "form-control is-invalid" : "form-control"} value={user.firstname} onChange={handleChange}/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="lastname" className="form-label">{t('users.lastname')} *</label>
                            <input id="lastname" type="text" name="lastname" className={(!formValid && user.lastname=="" ) ? "form-control is-invalid" : "form-control"} value={user.lastname} onChange={handleChange}/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="company" className="form-label">{t('users.company')}</label>
                            <input id="company" type="text" name="company" className="form-control" value={user.company} onChange={handleChange}/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="reknro" className="form-label">{t('users.reknro')}</label>
                            <input id="reknro" type="text" name="reknro" className="form-control" value={user.reknro} onChange={handleChange}/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="email" className="form-label">{t('users.email')} *</label>
                            <input id="email" type="email" name="email" className={(!formValid && user.email=="" ) ? "form-control is-invalid" : "form-control"} value={user.email} onChange={handleChange}/>
                        </div>

                        <div className="mt-3 mb-3">
                            <label htmlFor="address" className="form-label">{t('users.address')} *</label>
                            <input id="address" type="text" name="address" className={(!formValid && user.address=="" ) ? "form-control is-invalid" : "form-control"} value={user.address} onChange={handleChange}/>
                        </div>

                        <div className="row">
                            <div className="col-8">
                                <div className="mb-3">
                                    <label htmlFor="city" className="form-label">{t('users.city')} *</label>
                                    <input id="city" type="text" name="city" className={(!formValid && user.city=="" ) ? "form-control is-invalid" : "form-control"} value={user.city} onChange={handleChange}/>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="mb-3">
                                    <label htmlFor="zip" className="form-label">{t('users.zip')} *</label>
                                    <input id="zip" type="text" name="zip" className={(!formValid && user.zip=="" ) ? "form-control is-invalid" : "form-control"}value={user.zip} onChange={handleChange}/>
                                </div>
                            </div>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="userlang" className="form-label">{t('users.userlang')} *</label>
                            <select id="userlang" className="form-select" name="userlang" value={user.userlang} onChange={handleChange}>
                                <option value="en">{t('lang.en')}</option>
                                <option value="fi">{t('lang.fi')}</option>
                                <option value="no">{t('lang.no')}</option>
                            </select>
                        </div>

                        <h4>{t('users.payment-type')}</h4>

                        <div className="form-check">
                            <input id="paymentinvoice" className="form-check-input" type="radio" name="paymenttype" value="invoice" checked={user.paymenttype=='invoice' ? true : false} onChange={handleChange}/>
                            <label className="form-check-label" htmlFor="paymentinvoice">{t('users.payment-invoice')}</label>
                        </div>
                        <div className="form-check">
                            <input id="paymenteinvoice" className="form-check-input" type="radio" name="paymenttype" value="einvoice" checked={user.paymenttype=='einvoice' ? true : false} onChange={handleChange}/>
                            <label className="form-check-label" htmlFor="paymenteinvoice">{t('users.payment-einvoice')}</label>
                        </div>
                        <div className="form-check">
                            <input id="paymentemail" className="form-check-input" type="radio" name="paymenttype" value="email" checked={user.paymenttype=='email' ? true : false} onChange={handleChange}/>
                            <label className="form-check-label" htmlFor="paymentemail">{t('users.payment-email')}</label>
                        </div>


                        <div className="mt-3 mb-3">
                            <label htmlFor="einvoicingaddress" className="form-label">{t('users.einvoicing-address')}</label>
                            <input id="einvoicingaddress" type="text" name="einvoicingaddress" className="form-control" value={user.einvoicingaddress} onChange={handleChange}/>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="einvoicingbrokerid" className="form-label">{t('users.einvoicing-broker-id')}</label>
                            <input id="einvoicingbrokerid" type="text" name="einvoicingbrokerid" className="form-control" value={user.einvoicingbrokerid} onChange={handleChange}/>
                        </div>

                        {saved &&
                        <div className="mt-3 alert alert-success" role="alert">{t('users.saved')}</div>
                        }

                        <div className="d-flex justify-content-between mt-5">
                            <button type="button" className="btn btn-primary" onClick={saveUser}>{t('adminapp.save')}</button>
                        </div>
                    </form>

                }
            </div>
        </div>
	</div>);
}

export default Settings;