import React, {useEffect} from 'react'
import axios from 'axios';
import { useTranslation } from "react-i18next";
import {API} from '../../Configuration';
import { useAuth } from "../../Auth.js";
import BaseCircleChart from '../../Utils/BaseCircleChart';

const NearDays = (props) => {
    const { t } = useTranslation();

    const auth = useAuth();
    const token = localStorage.getItem('t');

    const [stats, setStats] = React.useState({});

    useEffect(() => {
		if (props.feedback.id) {
            axios.get(API + '/feedbacks/stats/neardays?id='+props.feedback.id, {headers: { Authorization: `Bearer ${token}`} })
            .then(function (response) {
                if (response.data) {
                    setStats(response.data)
                }
            })
            .catch(function(error) {
            });
		}
	}, [props.feedback])

	return (
        <div className="feedback-stats-container mt-4 pb-4 border-bottom">
            <div className="row">
                <div className="col-12">
                    <h4>{t('stats.neardays-title')}</h4>
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-12 col-md-6 mb-3 mb-md-0">
                    <BaseCircleChart item="today" stats={stats} titletype="big" title={t('stats.today')} width="300px" height="300px" />
                </div>
                <div className="col-12 col-md-6 mb-3 mb-md-0">
                    <BaseCircleChart item="yesterday" stats={stats} titletype="big" title={t('stats.yesterday')} width="300px" height="300px" />
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-12 col-md-4 mb-3 mb-md-0">
                    <BaseCircleChart item="minus2days" stats={stats} titletype="big" width="140px" height="140px" />
                </div>
                <div className="col-12 col-md-4 mb-3 mb-md-0">
                    <BaseCircleChart item="minus3days" stats={stats} titletype="big" width="140px" height="140px" />
                </div>
                <div className="col-12 col-md-4 mb-3 mb-md-0">
                    <BaseCircleChart item="minus4days" stats={stats} titletype="big" width="140px" height="140px" />
                </div>
            </div>
        </div>
    );
}

export default NearDays;