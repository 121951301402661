import React, {useEffect, useRef, useState} from 'react'
import axios from 'axios';
import moment from 'moment';
import { useTranslation } from "react-i18next";
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import {API} from '../../Configuration';
import { useAuth } from "../../Auth.js";

const Last30Days = (props) => {
    const { t } = useTranslation();

    const auth = useAuth();
    const token = localStorage.getItem('t');
    

    const [stats, setStats] = React.useState({});

    let options = {
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                enabled: true,
                intersect: true,
                mode: 'index',
                callbacks: {
                    /*title: function(tooltipItems, data) {
                        var dataitem = tooltipItems[0].dataset.data[tooltipItems[0].datasetIndex];
                        return moment(dataitem.x, 'DD-MM-YYYY').format(t('dateformat-short'))
                    },*/
                    label: function(context) {
                        var dataitem = context.dataset.data[context.dataIndex];
                        var label = t('stats.average');
                        label += ' ' + parseFloat(dataitem.y).toFixed(1) + ', ';
                        label += parseFloat(dataitem.c).toFixed(0) + ' ' + t('stats.pcs');
                        return label;
                    }
                }
            },
        },
        scales: {
            x: {
                title: {
                    display: true,
                    text: t('stats.bar-x-title')
                },
                /*ticks: {
                    callback: function(value, index, ticks) {
                        return moment(value, 'DD-MM-YYYY').format(t('dateformat-short'));
                    }
                }*/
            },
            y: {
                title: {
                    display: true,
                    text: t('stats.bar-y-title')
                },
                min: 0.0,
                max: 5.0
            }
        },
        responsive: true,
        animation: {
            animateRotate : true,
            animateScale : true
        }
    }

    const chartRef = useRef(null);

    const [chartData, setChartData] = useState({
        datasets: [],
    });

    useEffect(() => {
        const chart = chartRef.current;
        if (chart && stats && stats.dayitems) {
            setChartData({
                datasets: [{
                    backgroundColor: '#00aeff',
                    borderWidth: 0,
                    data: stats.dayitems
                }]
            });
        }
    }, [stats]);

    useEffect(() => {
		if (props.feedback.id) {
            axios.get(API + '/feedbacks/stats/last30days?id='+props.feedback.id, {headers: { Authorization: `Bearer ${token}`} })
            .then(function (response) {
                if (response.data) {
                    setStats(response.data)
                }
            })
            .catch(function(error) {
            });
		}
	}, [props.feedback])

	return (
        <div className="feedback-stats-container mt-4 pb-4 border-bottom">
            <div className="row">
                <div className="col-12">
                    <h4>{t('stats.last30days-title')}</h4>
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-12">
                    <Bar data={chartData} options={options} ref={chartRef} />
                </div>
            </div>
        </div>
    );
}

export default Last30Days;