import React, {useEffect} from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import { useTranslation } from "react-i18next";
import {API} from '../Configuration';
import BaseCircleChart from '../Utils/BaseCircleChart';
import LanguageChooser from '../Utils/LanguageChooser';
import Whistleblow from './Whistleblow';


const Feedback = () => {
    const { t, i18n } = useTranslation();

    const [feedback, setFeedback] = React.useState({});
    const [numberSent, setNumberSent] = React.useState(false);
    const [stats, setStats] = React.useState({});
    const [message, setMessage] = React.useState("");
    const [messageReturnCode, setMessageReturnCode] = React.useState(null);
    const [selectedCode, setSelectedCode] = React.useState(null);
    const [attachment, setAttachment] = React.useState(null);
    const [attachmentError, setAttachmentError] = React.useState(false);
    const [whistleblowCode, setWhistleblowCode] = React.useState("");
    const [messageSent, setMessageSent] = React.useState(false);
    let params = useParams();
    const navigate = useNavigate();

    // set locale for moment
    moment.locale(i18n.language);

    const handleMessageChange = (event) => {
        setMessage(event.target.value)
    }

    const handleWhistleblowCodeChange = (event) => {
        setWhistleblowCode(event.target.value)
    }

    const handleAttachmentChange = (event) => {

        setAttachmentError(false);
        let filename = event.target.files[0].name;
        if (filename) {
            let ext = filename.match(/\.([^\.]+)$/)[1];
            let isOk = true;
            switch (ext.toLowerCase()) { 
                case 'jpg':
                case 'jpeg':
                case 'png':
                case 'gif':
                case 'tif':
                case 'tiff':
                case 'zip':
                case 'doc':
                case 'docx':
                case 'xls':
                case 'xlsx':
                case 'ppt':
                case 'pptx':
                case 'pdf':
                case 'txt':
                case 'xml':
                case 'svg':
                break;
                default:
                    isOk = false;
            }
            if (isOk) {
                setAttachment(event.target.files[0])
            } else {
                setAttachmentError(true);
            }
        }
        
    }

    const sendMessage = () => {
        const formData = new FormData();
        formData.append('id', feedback.id);
        formData.append('message', message);
        formData.append('language', i18n.language);
        if (attachment) {
            formData.append('attachment', attachment);
        }
        axios.post(API + '/feedbacks/message', formData)
        .then(function (response) {
            if (response.data.success) {
                if (response.data.code) {
                    setMessageReturnCode(response.data.code)
                }
                setMessageSent(true)
            }
        })
        .catch(function (error) {
        });
    }

    const sendWhistleblowCode = (event) => {
        axios.get(API + '/whistleblow/checkcode?code='+whistleblowCode)
        .then(function (response) {
            if (response.data.success) {
                setSelectedCode(response.data.code)
            }
        })
        .catch(function(error) {
        });
        
    }

    const checkNumber = (number) => {
        if (feedback && feedback.id) {
            //clearTimeout(timer);
            axios.post(API + '/collect', {
                id: feedback.id,
                number: number,
                type: 'feedback'
            })
            .then(function (response) {
                if (response.data.success) {
                    setNumberSent(true)
                    // set timer to change back to numbers
                    //timer = setTimeout(() => setNumberSent(false), 5000);
				}
            })
            .catch(function (error) {
            });
        }
    }

    useEffect(() => {
		if (params.feedbackid) {
			axios.get(API + '/feedback?id='+params.feedbackid)
			.then(function (response) {
				if (response.data.success) {
                    setFeedback(response.data.feedback)
                    axios.get(API + '/feedbacks/stats?id='+params.feedbackid)
                    .then(function (response) {
                        if (response.data) {
                            setStats(response.data)
                        }
                    })
                    .catch(function(error) {
                    });
				} else {
                    navigate("/")
                }
			})
			.catch(function(error) {
			});
		}
	}, [params.feedbackid])

    useEffect(() => {
		if (feedback.id) {
            axios.get(API + '/feedbacks/stats?id='+feedback.id)
            .then(function (response) {
                if (response.data) {
                    setStats(response.data)
                }
            })
            .catch(function(error) {
            });
		}
	}, [feedback])

    
	return (
	<div className="App vw-100 vh-100 d-flex align-items-start justify-content-center">
        {feedback.id &&

            <div className="container">
                <div className="row blue-bg">
                    <div className="companynamecontainer col-12 col-md-6 text-center text-md-start text-white">{feedback.name}</div>
                    <div className="splogocontainer d-none d-md-block col-12 col-md-6 text-center text-md-end noprint"><img id="splogo" src="/public/sp_logo_white.png" alt={t('title')} /></div>
                </div>

                <div className="d-flex flex-column-reverse flex-lg-column">
                    {(feedback.type!='message' && feedback.type!='whistleblow') 
                    ?
                    <div className="noprint">
                        <div className="row blue-bg">
                            <div className="col-12">
                                {!!(stats) &&
                                    <div className="d-flex justify-content-center text-white py-3">{t('feedback.reviews-title')}</div>
                                }
                            </div>
                        </div>
                        <div id="statisticcontainer" className="row blue-bg bottomimg justify-content-center pt-4">
                            {!!(stats.today && stats.today.avg) &&
                                <div className="col-12 col-sm-6 col-md-3 col-lg-2 stat-item d-flex justify-content-center"><BaseCircleChart type="today" stats={stats} darkTheme={true} /></div>
                            }
                            {!!(stats.week && stats.week.avg) &&
                                <div className="col-12 col-sm-6 col-md-3 col-lg-2 stat-item d-flex justify-content-center"><BaseCircleChart type="week" stats={stats} darkTheme={true} /></div>
                            }
                            {!!(stats.month && stats.month.avg) &&
                                <div className="col-12 col-sm-6 col-md-3 col-lg-2 stat-item d-flex justify-content-center"><BaseCircleChart type="month" stats={stats} darkTheme={true} /></div>
                            }
                        </div>
                    </div>
                    :
                    <div className="d-none d-lg-block noprint">
                        <div style={{minHeight: 115}} className="row blue-bg bottomimg justify-content-center pt-4">
                        </div>
                    </div>
                    }
                    <div className="">

                        {(feedback.type=='whistleblow') && 
                        <>

                            {selectedCode 
                            ? (
                                <>
                                     <div className="contentcontainer">
                                        <Whistleblow feedback={feedback} code={selectedCode}/>
                                     </div>
                                </>
                            )
                            : (
                                <>
                                    <div className="contentcontainer">
                                        <div id="report" className="row">
                                            <div className="col-12 col-lg-8 offset-lg-2 mb-5">
                                                <div className="thankscontainer">{(feedback.question) ? feedback.question : t('feedback.default-question-whistleblow')}</div>
                                                <>
                                                    {messageSent
                                                    ? 
                                                        <>
                                                            <p className="text-center">{t('feedback.messagesent-whistleblow')}</p>
                                                            {messageReturnCode &&
                                                                <p className="text-center">{t('feedback.messagesent-whistleblow-code')} <br/><strong style={{"fontSize": "2.4rem"}}>{messageReturnCode}</strong></p>
                                                            }
                                                        </>
                                                    :
                                                        <>
                                                            <p>{t('feedback.messagelabel-whistleblow')}</p>
                                                            <form>
                                                            <div className="mb-3">
                                                                <textarea className="form-control" id="sendMessage" rows="6" value={message} onChange={handleMessageChange}></textarea>
                                                            </div>
                                                            {attachmentError &&
                                                                <div className="alert alert-warning" role="alert">
                                                                    {t('feedback.whistleblow-attachment-error')} <br/>
                                                                </div>
                                                            }
                                                            <div className="mb-3">
                                                                <input className="form-control" id="attachment" type="file" name="attachment" onChange={handleAttachmentChange} />
                                                                <div className="form-text">{t('feedback.messagefileinfo-whistleblow')}</div>
                                                            </div>
                                                            <div className="d-grid gap-2">
                                                                <button className="btn btn-primary btn-lg" type="button" onClick={sendMessage}>{t('feedback.messagetext-button')}</button>
                                                            </div>
                                                            </form>
                                                        </>
                                                    }
                                                </>
                                            </div>
                                        </div>
                                        <div id="report" className="row">
                                            <div className="col-12 col-lg-8 offset-lg-2 mb-5">
                                                <hr/>
                                                <p className="text-center">{t('feedback.existing-title-whistleblow')}</p>
                                                <form>
                                                <div className="mb-3" style={{maxWidth: 340, margin: "0 auto"}}>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control" value={whistleblowCode} onChange={handleWhistleblowCodeChange}/>
                                                        <button type="button" className="btn btn-primary btn-sm" onClick={sendWhistleblowCode}>{t('feedback.existing-button-whistleblow')}</button>
                                                    </div>
                                                </div>
                                                </form>

                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}

                            
                        </>
                        }

                        {(feedback.type!=='whistleblow') &&
                            <>
                            {(numberSent || feedback.type=='message')
                            ? 
                                <div className="contentcontainer">
                                    <div id="report" className="row">
                                        <div className="col-12 col-lg-8 offset-lg-2 mb-5">
                                            <div className="thankscontainer">{(feedback.type!='message') ? t('feedback.thankyou') : ((feedback.question) ? feedback.question : t('feedback.default-question'))}</div>
                                            {(feedback.type=='both' || feedback.type=='message') &&
                                            <>
                                                {messageSent
                                                ? 
                                                    <p className="text-center">{t('feedback.messagesent')}</p>
                                                :
                                                    <>
                                                        {(feedback.type=='message') ? <p>{t('feedback.messagelabel')}</p> : <p>{t('feedback.messagetext')}</p>}
                                                        <form>
                                                        <div className="mb-3">
                                                            <textarea className="form-control" id="sendMessage" rows="6" value={message} onChange={handleMessageChange}></textarea>
                                                        </div>
                                                        {(feedback.handleattachment) &&
                                                        <>
                                                            {attachmentError &&
                                                                <div className="alert alert-warning" role="alert">
                                                                    {t('feedback.whistleblow-attachment-error')} <br/>
                                                                </div>
                                                            }
                                                            <div className="mb-3">
                                                                <input className="form-control" id="attachment" type="file" name="attachment" onChange={handleAttachmentChange} />
                                                                <div className="form-text">{t('feedback.messagefileinfo')}</div>
                                                            </div>
                                                        </>
                                                        }
                                                        <div className="d-grid gap-2">
                                                            <button className="btn btn-primary btn-lg" type="button" onClick={sendMessage}>{t('feedback.messagetext-button')}</button>
                                                        </div>
                                                        </form>
                                                    </>
                                                }
                                            </>
                                            }
                                            
                                        </div>
                                    </div>
                                </div>
                            : 
                                <div className="mb-5">
                                    <div id="numbers">
                                        <div className="titlecontainer d-flex justify-content-center">{(feedback.question) ? feedback.question : t('feedback.default-question')}</div>
                                        <div className="d-flex flex-wrap justify-content-center">
                                            <div className="d-md-none labeltxt">{(feedback.worst) ? feedback.worst : t('feedback.default-option-worst')}</div>
                                            <div id="number-1" className="d-flex align-items-center justify-content-center numberselect collectitem" onClick={() => checkNumber('1')}>1</div>
                                            <div id="number-2" className="d-flex align-items-center justify-content-center numberselect collectitem" onClick={() => checkNumber('2')}>2</div>
                                            <div id="number-3" className="d-flex align-items-center justify-content-center numberselect collectitem" onClick={() => checkNumber('3')}>3</div>
                                            <div id="number-4" className="d-flex align-items-center justify-content-center numberselect collectitem" onClick={() => checkNumber('4')}>4</div>
                                            <div id="number-5" className="d-flex align-items-center justify-content-center numberselect collectitem" onClick={() => checkNumber('5')}>5</div>
                                            <div className="d-md-none labeltxt">{(feedback.best) ? feedback.best : t('feedback.default-option-best')}</div>
                                        </div>
                                        <div className="labelcontainer d-md-flex justify-content-between">
                                            <div className="labeltxt">{(feedback.worst) ? feedback.worst : t('feedback.default-option-worst')}</div>
                                            <div className="labeltxt"></div>
                                            <div className="labeltxt">{(feedback.best) ? feedback.best : t('feedback.default-option-best')}</div>
                                        </div>
                                    </div>
                                </div>
                            }
                            </>
                        }
                    </div>
                </div>

                <div className="mobilelogo d-block d-md-none text-center">
                    <a href="http://www.suorapalaute.fi"><img id="splogo-mobile" src="/public/sp_logo.png" alt={t('title')} /></a>
                </div>

                <div className="contentcontainer text-end noprint">
                    <div id="datecontainer">
                        <LanguageChooser showDate="true" />
                    </div>
                </div>
            
            </div>

        }

	</div>);
}

export default Feedback;