import React, {useEffect} from 'react'
import { useRouteError } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ErrorPage = () => {

    const { t, i18n } = useTranslation();

    const error = useRouteError();
    console.error(error);

    return (
        <div id="ErrorPage" className="App vw-100 vh-100 d-flex align-items-center justify-content-center">
            <div>
                <h1>{t('errorpage.title')}</h1>
                <p>{t('errorpage.info')}</p>
                <p>
                    <i>{(error) ? error.statusText || error.message : ""}</i>
                </p>
            </div>
        </div>
    );
}

export default ErrorPage;

