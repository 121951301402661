import React, {useEffect} from 'react'
import { useNavigate, Outlet } from 'react-router-dom';
import { useAuth } from "../Auth.js";
import Header from "./Header"

const AdminApp = () => {

    const auth = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
		if (!auth.user) {
            navigate("/signin")
		}
	}, [auth.user])

    return (
		<div id="adminapp" className='pb-5'>
            <Header />
            <Outlet />
        </div>
    );
}

export default AdminApp;

