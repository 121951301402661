import React, {useEffect} from 'react'
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from "react-i18next";
import {API} from '../Configuration';
import { useAuth } from "../Auth.js";

import NearDays from "./FeedbackStats/NearDays"
import Last30Days from "./FeedbackStats/Last30Days"
import LastWeek from "./FeedbackStats/LastWeek"
import LastMonth from "./FeedbackStats/LastMonth"
import LastYear from "./FeedbackStats/LastYear"



const FeedbackStats = (props) => {
    const { t } = useTranslation();

    const auth = useAuth();
    const token = localStorage.getItem('t');

    const [feedback, setFeedback] = React.useState(null);

    // clone user object
    useEffect(() => {
        if (props.feedback) {
            setFeedback(JSON.parse(JSON.stringify(props.feedback)))
        }
	}, [props.feedback])

	return (
        <>
            {feedback &&
                <>
                    <div className="row">
                        <div className="col-12 text-center text-md-start">
                            <NearDays feedback={feedback} />
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="col-12 col-md-6 text-center text-md-start">
                            <LastWeek feedback={feedback} />
                        </div>
                        <div className="col-12 col-md-6 text-center text-md-start">
                            <LastMonth feedback={feedback} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 text-center text-md-start">
                            <Last30Days feedback={feedback} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 text-center text-md-start">
                            <LastYear feedback={feedback} />
                        </div>
                    </div>
                </>
            }
        </>
    );
}

export default FeedbackStats;