import React, {useEffect} from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useAuth } from "../Auth.js";

const ActivateUser = () => {
    const { t, i18n } = useTranslation();

    const auth = useAuth();
    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();
    const code = searchParams.get("code")

    const [password, setPassword] = React.useState('');
    const [password2, setPassword2] = React.useState('');
    const [emailSended, setEmailSended] = React.useState(false);
    const [terms, setTerms] = React.useState(false);
    const [formValid, setFormValid] = React.useState(true);
    const [passwordLengthValid, setPasswordLengthValid] = React.useState(true);
    const [passwordSameValid, setPasswordSameValid] = React.useState(true);

    const handleChange = (event) => {
        if (event.target.id=='email') {
            setEmail(event.target.value)
        } else if (event.target.id=='password') {
            setPassword(event.target.value)
        } else if (event.target.id=='password2') {
            setPassword2(event.target.value)
        } else if (event.target.id=='terms') {
            setTerms(event.target.checked)
        }
    }

    const activate = () => {

        let isValid = true;
        if (!code) {
            isValid = false;
        }
        if (password=='' ||
            password2=='') {
            isValid = false;
        }
        if (password!==password2) {
            isValid = false;
            setPasswordSameValid(false)
        } else {
            setPasswordSameValid(true)
        }
        if (password.length<8) {
            isValid = false;
            setPasswordLengthValid(false)
        } else {
            setPasswordLengthValid(true)
        }

        if (isValid) {
            let isActivated = auth.activate(code, password, terms);
            if (isActivated) {
                //navigate("/signin")
            } else {
                setError(true)
            }
            setFormValid(true)
        } else {
            setFormValid(false)
        }

    }

    useEffect(() => {
		if (auth.user) {
            navigate("/admin/feedbacks")
		}
	}, [auth.user])

	return (
	<div id="adminapp" className="d-flex h-100 align-items-center justify-content-center">

        <div className="col-8" style={{maxWidth: 600}}>
            <div className="card">
                <div className="card-body">
                    <img className="mx-auto mt-2" src='/public/sp_logo_200.png' style={{width: 200}}/>
                    <form className="p-4">
                        {!emailSended 
                        ? (
                            <>
                            <div className="mb-3">
                                <p>{t('signin.activate-title')}</p>
                            </div>

                            {!passwordLengthValid &&
                                <div className="alert alert-warning" role="alert">{t('signin.resetpassword-length-alert')}</div>
                            }

                            {!passwordSameValid &&
                                <div className="alert alert-warning" role="alert">{t('signin.resetpassword-same-alert')}</div>
                            }
                            
                            <div className="mb-4">
                                <label htmlFor="password" className="form-label">{t('signin.password')}</label>
                                <input id="password" type="password" className={(!formValid && !password) ? "form-control is-invalid" : "form-control"} value={password} onChange={handleChange} />
                            </div>
                            <div className="mb-4">
                                <label htmlFor="password2" className="form-label">{t('signin.password2')}</label>
                                <input id="password2" type="password" className={(!formValid && !password2) ? "form-control is-invalid" : "form-control"} value={password2} onChange={handleChange} />
                            </div>

                            <div className="mb-4">
                                <div className="form-check">
                                    <input id="terms" className={(!formValid && !terms ) ? "form-check-input is-invalid" : "form-check-input"} type="checkbox" checked={terms} onChange={handleChange} />
                                    <label className="form-check-label" htmlFor="terms">{t('signin.terms')}</label>
                                </div>
                            </div>


                            <div className="d-flex justify-content-start">
                                <button type="button" className="btn btn-primary" onClick={activate}>{t('signin.activate-button')}</button>
                            </div>
                            </>
                        )
                        : (
                            <div className="mb-3">
                                <p>{t('signin.activate-redirect')}</p>
                            </div>
                        )}
                    </form>
                </div>
            </div>
            <div className='text-muted text-end pt-1'>
                {(i18n.language=='en') 
                ? <p><a href="#" onClick={() => i18n.changeLanguage('fi')}>{t('lang.fi')}</a></p>
                : <p><a href="#" onClick={() => i18n.changeLanguage('en')}>{t('lang.en')}</a></p>
                }
            </div>
        </div>

	</div>);
}

export default ActivateUser;