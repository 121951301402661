import React, {useEffect} from 'react'
import { useParams, NavLink, Link } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import { useTranslation } from "react-i18next";
import {API} from '../Configuration';
import { useAuth } from "../Auth.js";

const Header = () => {

    const { t, i18n } = useTranslation();

    const auth = useAuth();

    return (
        <header className="py-3 mb-3 mb-md-5 border-bottom">
            <div className="container d-flex flex-wrap justify-content-between align-items-center">
                <a href="/admin" className="flex me-1 mb-3 mb-md-0 text-dark text-decoration-none">
                    <img src='/public/sp_logo_200.png' style={{maxWidth: 120}}/>
                </a>
                <div className="navigation">
                    <ul className="nav">
                        <li className="nav-link px-1"><NavLink to="/admin/Feedbacks" className={({ isActive }) => isActive ? "nav-link active" : "nav-link" }>{t('adminapp.nav-Feedbacks')}</NavLink></li>
                        {(auth.user && (auth.user.og || auth.user.rs)) &&
                        <>
                        <li className="nav-link px-1"><NavLink to="/admin/users" className={({ isActive }) => isActive ? "nav-link active" : "nav-link" }>{t('adminapp.nav-users')}</NavLink></li>
                        </>
                        }
                        {(auth.user && auth.user.og) &&
                        <>
                        <li className="nav-link px-1"><NavLink to="/admin/resellers" className={({ isActive }) => isActive ? "nav-link active" : "nav-link" }>{t('adminapp.nav-resellers')}</NavLink></li>
                        <li className="nav-link px-1"><NavLink to="/admin/billing" className={({ isActive }) => isActive ? "nav-link active" : "nav-link" }>{t('adminapp.nav-billing')}</NavLink></li>
                        </>
                        }
                        {(auth.user && (!auth.user.og && !auth.user.rs)) &&
                        <>
                        <li className="nav-link px-1"><NavLink to="/admin/settings" className={({ isActive }) => isActive ? "nav-link active" : "nav-link" }>{t('adminapp.nav-settings')}</NavLink></li>
                        </>
                        }
                        {(auth.user && auth.user.rs) &&
                        <>
                        <li className="nav-link px-1"><NavLink to="/admin/reseller" className={({ isActive }) => isActive ? "nav-link active" : "nav-link" }>{t('adminapp.nav-reseller')}</NavLink></li>
                        </>
                        }
                    </ul>
                </div>

                <div className="actions">
                    <button className="btn btn-outline-primary me-1 me-md-2" onClick={() => auth.signout()} title={t('adminapp.signout')}><i className="bi bi-box-arrow-right"></i><span className="d-none d-sm-inline-block ps-2"> {t('adminapp.signout')}</span></button>
                </div>

            </div>
        </header>
    );
}

export default Header;
