import React, { useState, useEffect, useContext, createContext } from "react";

import axios from 'axios';
import { useTranslation } from "react-i18next";
import {API} from './Configuration';
import i18n, { t } from "i18next";

const authContext = createContext();

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }) {
	const auth = useProvideAuth();
	return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
	return useContext(authContext);
};

// Provider hook that creates auth object and handles state
function useProvideAuth() {

	axios.defaults.headers.post['Content-Type'] ='application/x-www-form-urlencoded';

	const [user, setUser] = useState(null);
	
	// Wrap any Firebase methods we want to use making sure ...
	// ... to save the user to state.
	const signin = (email, password) => {
		
	};

	const signout = () => {
		axios.post(API + '/auth/signout', {id: user.id})
		.then(function (response) {
			setUser(false);
			localStorage.localStorage.removeItem('t');
		})
		.catch(function (error) {
		});
	};
	
	const sendPasswordResetEmail = (email) => {
		axios.post(API + '/auth/sendpasswordreset', {
			email: email,
			subject: t('signin.resetemail.subject'),
			message: t('signin.resetemail.message'),
			language: i18n.language
		})
		.then(function (response) {
			if (response.data.success) {
				
			}
		})
		.catch(function (error) {
			console.error(error)
		});

		return true;
	};
	const activate = (code, password, terms) => {
		axios.post(API + '/auth/activate', {
			code: code,
			password: password,
			terms: terms
		})
		.then(function (response) {
			if (response.data.success) {
				setUser(response.data.user);
				localStorage.setItem('t', response.data.token);
			}
		})
		.catch(function (error) {
			console.error(error)
		});
		return true;
	};
	const setAuthUser = (user) => {
		setUser(user)
	};
	// Return the user object and auth methods
	return {
		user,
		signin,
		signout,
		sendPasswordResetEmail,
		setAuthUser,
		activate
	};
}