import React, {useEffect, useState} from 'react'
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from "react-i18next";
import {API} from '../Configuration';
import moment from 'moment';

const Reseller = (props) => {
    const { t, i18n } = useTranslation();

    const token = localStorage.getItem('t');

    // set locale for moment
    moment.locale(i18n.language);

    const [reseller, setReseller] = React.useState(null);
    const [newuser, setNewuser] = React.useState({
        name: "",
        email: "",
        code: ""
    });
    const [adduserformValid, setAdduserFormValid] = React.useState(true);

    const handleUserChange= (event) => {
        let field = (event.target.type === 'radio') ? event.target.name : event.target.id;
        let value = (event.target.type === 'checkbox') ? event.target.checked : event.target.value;
        setNewuser({
			...newuser,
			[field]: value
		});
    }

    const addUser = () => {
        let isValid = true;
        if (newuser.name=="" || newuser.email=="") {
            isValid = false;
        }
        if (isValid) {
            // post reseller data to backend
            setAdduserFormValid(true)

            axios.post(API + '/resellers/adduser', {
                ...newuser,
                id: reseller.id,
                language: i18n.language
            }, {headers: { Authorization: `Bearer ${token}`} })
            .then(function (response) {
                if (response.data.success) {
                    setAdduserFormValid(true)
                    setNewuser({
                        name: "",
                        email: ""
                    })

                    setReseller({
                        ...reseller,
                        users: response.data.users
                    });
				}
            })
            .catch(function (error) {
            });
        } else {
            setAdduserFormValid(false)
        }

    }

    const deleteUser = (user) => {
        if (confirm(t('resellers.user.confirmdelete'))) {
            axios.get(API + '/resellers/deleteuser?id=' + reseller.id + '&uid=' + user.code, {headers: { Authorization: `Bearer ${token}`} })
            .then(function (response) {
                if (response.data.success) {
                    setReseller({
                        ...reseller,
                        users: response.data.users
                    });
                }
            })
            .catch(function(error) {
            });
        }
    }


    const loadReseller = () => {
        axios.get(API + '/resellers/me', {headers: { Authorization: `Bearer ${token}`} })
        .then(function (response) {
            if (response.data.success) {
                setReseller(response.data.reseller);
            }
        })
        .catch(function(error) {
        });
    }

    // load reseller if not
    useEffect(() => {
        if (!reseller) {
            loadReseller()
        }
	})

	return (

        <div className="container">
            <div className="row gx-5">
                <div className="col-12 col-md-3">
                </div>
                <div className="col-12 col-md-9 pt-3 pt-md-0">

                    <div className="row">
                        <div className="col-12">
                            {reseller &&
                                <form className="pb-4">

                                    <div className="d-flex justify-content-between">
                                        {reseller.id &&
                                        <h3>{reseller.company} / {reseller.reknro}</h3>
                                        }
                                    </div>

                                    <div className="row mt-4">
                                        <div className="col-12">
                                            <h5>{t('resellers.users-title')}</h5>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            {reseller.users &&
                                                <>
                                                    {reseller.users.map((item, index) =>
                                                        <div key={item.email + '_' + index} className="row mb-1">
                                                            <div className="col-md-5">{item.name}</div>
                                                            <div className="col-md-5">{item.email}</div>
                                                            <div className="col-md-2"><button type="button" className="btn btn-outline-danger btn-sm" onClick={() => deleteUser(item)}>{t('resellers.user.delete')}</button></div>
                                                        </div>
                                                    )}
                                                </>
                                            }
                                        </div>
                                    </div>

                                    {reseller.id &&
                                    <>
                                    <div className="row mt-4">
                                        <div className="col-12">
                                            <p>{t('resellers.adduser-title')}</p>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-5">
                                            <div className="mb-3">
                                                <label htmlFor="name" className="form-label">{t('resellers.user.name')} *</label>
                                                <input id="name" type="text" name="name" className={(!adduserformValid && newuser.name=="" ) ? "form-control is-invalid" : "form-control"} value={newuser.name} onChange={handleUserChange}/>
                                            </div>
                                        </div>
                                        <div className="col-md-5">
                                            <div className="mb-3">
                                                <label htmlFor="email" className="form-label">{t('resellers.user.email')} *</label>
                                                <input id="email" type="text" name="email" className={(!adduserformValid && newuser.email=="" ) ? "form-control is-invalid" : "form-control"} value={newuser.email} onChange={handleUserChange}/>
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="mb-3">
                                                <label htmlFor="adduser" className="form-label">&nbsp;</label>
                                                <button type="button" className="btn btn-primary d-block" onClick={addUser}>{t('resellers.user.add')}</button>
                                            </div>
                                        </div>
                                    </div>
                                    </>
                                    }   
                                
                                </form>

                            }
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>


        
	);
}

export default Reseller;