import React from 'react'
import moment from 'moment';
import { useTranslation } from "react-i18next";

const LanguageChooser = (params) => {
    const { t, i18n } = useTranslation();

    // set locale for moment
    moment.locale(i18n.language);
    
	return (
	<div>
        {(i18n.language=='en') &&
            <>
            <a className="pe-2" href="#" onClick={() => i18n.changeLanguage('fi')}>{t('lang.fi')}</a> 
            <a className="pe-2" href="#" onClick={() => i18n.changeLanguage('no')}>{t('lang.no')}</a> 
            </>
        }
        {(i18n.language=='fi') &&
            <>
            <a className="pe-2" href="#" onClick={() => i18n.changeLanguage('en')}>{t('lang.en')}</a> 
            <a className="pe-2" href="#" onClick={() => i18n.changeLanguage('no')}>{t('lang.no')}</a> 
            </>
        }
        {(i18n.language=='no') &&
            <>
            <a className="pe-2" href="#" onClick={() => i18n.changeLanguage('fi')}>{t('lang.fi')}</a> 
            <a className="pe-2" href="#" onClick={() => i18n.changeLanguage('en')}>{t('lang.en')}</a> 
            </>
        }
        {(params.showDate) &&
            <>
            {moment().format(t('dateformat-long'))}
            </>
        }  
	</div>);
}

export default LanguageChooser;