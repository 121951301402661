import React, {useEffect, useState, useRef} from 'react';
import 'chart.js/auto';
import { Doughnut } from 'react-chartjs-2'
import { Chart } from 'chart.js';
import { useTranslation } from "react-i18next";


const BaseCircleChart = (props) => {

	const { t, i18n } = useTranslation();

	const [pros, setPros] = React.useState([0.0, "", 0]);

    let options = {
        plugins: {
            legend: {
                display: false
            },
			title: {
				display: (props.titletype && props.titletype=='big') ? false : true,
				position: 'bottom',
				font: {
					size: 16,
					style: 'normal',
					weight: 'normal',
					lineHeight: '1.2'

				},
				color: props.darkTheme ? '#fff' : '#212529',
				text: [pros[1]]
			},
			tooltip: {
				enabled: false
			},
			responsive: true,
        },
        elements: {
            center: {
				text: pros[0]+'',
				color: props.darkTheme ? '#fff' : '#212529', 
				fontStyle: 'Arial',
				sidePadding: 40
			}
        },
        animation: {
            animateRotate : true,
            animateScale : true
        }
    }

	const plugins = [{
		beforeDraw: function(chart) {
			if (chart.config.options.elements.center) {
				//Get ctx from string
				var ctx = chart.ctx;
		  
				//Get options from the center object in options
				var centerConfig = chart.config.options.elements.center;
				var fontStyle = centerConfig.fontStyle || 'Arial';
				var txt = centerConfig.text;
				var color = centerConfig.color || '#212529';
				var sidePadding = centerConfig.sidePadding || 20;
				var sidePaddingCalculated = (sidePadding/100) * (chart.innerRadius * 2)
				//Start with a base font of 30px
				ctx.font = "20px " + fontStyle;
		  
				//Get the width of the string and also the width of the element minus 10 to give it 5px side padding
				var stringWidth = ctx.measureText(txt).width;
				var elementWidth = (chart.innerRadius * 2) - sidePaddingCalculated;
		  
				// Find out how much the font can grow in width.
				var widthRatio = elementWidth / stringWidth;
				var newFontSize = Math.floor(20 * widthRatio);
				var elementHeight = (chart.innerRadius * 2);
		  
				// Pick a new font size so it will not be larger than the height of label.
				var fontSizeToUse = Math.min(newFontSize, elementHeight);
		  
				//Set font settings to draw it correctly.
				ctx.textAlign = 'center';
				ctx.textBaseline = 'middle';
				var centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
				var centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2);
				ctx.font = fontSizeToUse+"px " + fontStyle;
				ctx.fillStyle = color;
		  
				//Draw text in center
				ctx.fillText(txt, centerX, centerY);
			}
		} 
	}]

    const chartRef = useRef(null);

    const [chartData, setChartData] = useState({
        labels: [],
        datasets: [],
    });

	const randomValue = (min, max) => {
		let range = max - min;
		return min + range * Math.random();
	};

    useEffect(() => {
        const chart = chartRef.current;
        if (chart && props.stats) {
			let newtitle = "";
			let newpros = 0.0;
			let newcount = 0;

			if (props.title) {
				newtitle = props.title
			} else if (props.stats[props.item] && props.stats[props.item].title) {
				newtitle = props.stats[props.item].title
			}

			if (props.item) {
				if (props.stats[props.item] && props.stats[props.item].avg) {
					newpros = props.stats[props.item].avg
					newcount = props.stats[props.item].count
				}
			} else {
				if (props.type==='today' && props.stats.today) {
					if (props.stats.today.yesterday) {
						newpros = props.stats.today.avg
						newtitle = t('feedback.chart-yesterday')
					} else {
						newpros = props.stats.today.avg
						newtitle = t('feedback.chart-today')
					}
				} else if (props.type==='week' && props.stats.week) {
					newpros = props.stats.week.avg
					newtitle = t('feedback.chart-week')
				} else if (props.type==='month' && props.stats.month) {
					newpros = props.stats.month.avg
					newtitle = t('feedback.chart-month')
				}
			}

			//console.log(options)

			// for testing data 
			newpros = randomValue(3.1, 5.0)
			newcount = Number.parseFloat(randomValue(20, 169)).toFixed(0)

			setPros([Number.parseFloat(newpros).toFixed(1), newtitle, newcount])

            setChartData({
                labels: ['', ''],
                datasets: [{
                    data: [Number.parseFloat(newpros).toFixed(1), 6-Number.parseFloat(newpros).toFixed(1)],
                    backgroundColor: [props.darkTheme ? '#fff' : '#00aeff', props.darkTheme ? '#39c0ff' : '#b5e7ff'],
                    hoverBackgroundColor: [props.darkTheme ? '#fff' : '#00aeff', props.darkTheme ? '#39c0ff' : '#b5e7ff'],
                    borderColor: [props.darkTheme ? '#00aeff' : 'transparent', props.darkTheme ? '#00aeff' : 'transparent'],
                    hoverBorderColor: [props.darkTheme ? '#00aeff' : 'transparent', props.darkTheme ? '#00aeff' : 'transparent'],
                }]
            });
        }
    }, [props.stats]);

	let width = props.width;
	if (!width) {
		width = '200px'
	}

	let height = props.height;
	if (!height) {
		height = '200px'
	}

	return (
		<>
		<div className="mx-auto mx-md-0" style={{width: width, height: height}}>
			{props.stats &&
			<>
				<Doughnut data={chartData} options={options} plugins={plugins} ref={chartRef} />
			</>
			}
		</div>
		{(props.titletype && props.titletype=='big') &&
			<div className="my-3">
				<div className="fs-4">{pros[1]}</div>
				<div className="avgtitle"><span className="fs-2 pe-1">{pros[0]}</span> keskiarvo</div>
				<div className="counttitle"><span className="fs-3 pe-1">{pros[2]}</span> kpl</div>
			</div>	
		}
		</>
    );
}

export default BaseCircleChart;