import React, {useEffect, useState} from 'react'
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from "react-i18next";
import {API} from '../Configuration';
import ResellerForm from "./ResellerForm"
import PaginationList from '../Utils/PaginationList';

const defaultReseller = {
    company: "",
    reknro: "",
    users: []
}

const Reseller = () => {
    const { t, i18n } = useTranslation();

    const token = localStorage.getItem('t');

    const [selectedReseller, setSelectedReseller] = React.useState(null);
    const [resellers, setResellers] = React.useState(null);

    const selectReseller = (reseller) => {
        setSelectedReseller(reseller);
    }

    const clearReseller = () => {
        setSelectedReseller(null);
    }

    const addReseller = () => {
        setSelectedReseller(defaultReseller);
    }

    const loadResellers = () => {
        setResellers(null)
        axios.get(API + '/resellers/list', {headers: { Authorization: `Bearer ${token}`} })
        .then(function (response) {
            if (response.data) {
                setResellers(response.data.resellers)
            }
        })
        .catch(function(error) {
        });
    }

    const displayReseller = (item, props) => {
        return (
        <button className={(selectReseller && selectReseller.id==item.id) ? "list-group-item list-group-item-action active" : "list-group-item list-group-item-action"} onClick={() => selectReseller(item)}>
            <div className="ms-2 me-auto">
                <div className="fw-bold">{item.company}</div>
            </div>
        </button>
        );
    }

    useEffect(() => {
		if (!resellers) {
            loadResellers();
		}
	}, [])

	return (
	<div className="container">
        <div className="row gx-5">
            <div className="col-12 col-md-3">
                <div className="d-grid gap-2 mb-4">
                    <button className="btn btn-primary" onClick={addReseller}>{t('resellers.add')}</button>
                </div>
                {resellers &&
                    <PaginationList data={resellers} limit={10} search={true} itemrender={displayReseller} itemaction={displayReseller} searchkeys={['company']}/>
                }
            </div>
            <div className="col-12 col-md-9 pt-3 pt-md-0">
                <ResellerForm reseller={selectedReseller} reloadResellers={loadResellers} clearReseller={clearReseller} />
            </div>
        </div>
	</div>
    );
}

export default Reseller;