import { t } from 'i18next';
import React, {useState, useEffect} from 'react'
import Pagination from './Pagination';

const PaginationList = (props) => {

    const [data, setData] = React.useState(props.data);
    const [currentPage, setCurrentPage] = useState(1);
    // No of Records to be displayed on each page   
    const [recordsPerPage] = useState(props.limit ? props.limit : 10);

    // search
    const [searchStr, setSearchStr] = useState('');
    const [filteredList, setFilteredList] = useState([]);
    const [currentRecords, setCurrentRecords] = useState([]);
    const [nPages, setNPages] = useState(1);

    const isInSearchitems = (item) => {
        let isok = false;
        if (props.searchkeys) {
            props.searchkeys.forEach(key => {
                if (item[key].toLowerCase().indexOf(searchStr.toLowerCase())>-1) {
                    isok = true;
                }
            });

        }
        return isok;
    }

    const filterItems = () => {
        setFilteredList(data.filter(isInSearchitems));
    }

    const setPage = (page) => {
        //console.log(page)
        setCurrentPage(page)
    }

    const initPagination = () => {

        //setPage(1)

        // init pagination
        const indexOfLastRecord = currentPage * recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;

        // Records to be displayed on the current page
        setCurrentRecords(filteredList.slice(indexOfFirstRecord, indexOfLastRecord))
        setNPages(Math.ceil(data.length / recordsPerPage))
    }

    const BasicItemRender = (item) => {
        return (
        <button key={item.id} class="list-group-item list-group-item-action">
            {item.name ? item.name : item.id}
        </button>
        );
    }

    const BasicItemAction = (item) => {
        console.log(item)
    }

    const ItemRender = props.itemrender ? props.itemrender : BasicItemRender;
    const ItemAction = props.itemaction ? props.itemaction : BasicItemAction;

    const handleSearchChange = (event) => {
        setSearchStr(event.target.value)
    }

    useEffect(() => {
		if (props.data) {
            setData(props.data);
            filterItems();
		}
	}, [props.data])

    useEffect(() => {
		if (filteredList) {
            initPagination();
		}
	}, [filteredList, currentPage])

    useEffect(() => {
        filterItems();
	}, [searchStr])

	return (
    <div className="pagination-list">
        {props.search &&
            <div className="input-with-icon mb-3">
                <i className="bi bi-search input-icon icon-muted"></i>
                <input type="text" className="form-control form-control-sm" placeholder={t('adminapp.search')} aria-label={t('adminapp.search')} aria-describedby={t('adminapp.search')} value={searchStr} onChange={handleSearchChange} />
                {(searchStr!='') &&
                    <i className="bi bi-x input-icon-clear icon-muted" onClick={() => setSearchStr('')}></i>
                }
            </div>
        }
        {currentRecords &&
            <div className="list-group mb-3">
            {currentRecords.map((item) =>
                <ItemRender key={item.id} {...item} />
            )}
            </div>
        }
        <Pagination
            nPages={nPages}
            currentPage={currentPage}
            setCurrentPage={setPage}
        />
	</div>);
}

export default PaginationList;