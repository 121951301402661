import React, {useEffect} from 'react'
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from "react-i18next";
import {API} from '../Configuration';
import PaginationList from '../Utils/PaginationList';
import EditFeedback from "./EditFeedback"
import { useAuth } from "../Auth.js";


const defaultFeedback = {
    name: "", 
    type: "both",
    email: "",
    question: "",
    worst: "",
    best: "", 
    message: true
}

const Feedbacks = () => {
    const { t, i18n } = useTranslation();

    const auth = useAuth();
    const token = localStorage.getItem('t');

    const [selectedFeedback, setSelectedFeedback] = React.useState(null);
    const [feedbacks, setFeedbacks] = React.useState(null);
    const [selectedUserId, setSelectedUserId] = React.useState('');
    const [users, setUsers] = React.useState(null);

    const selectFeedback = (feedback) => {
        setSelectedFeedback(feedback);
    }
    
    const clearFeedback = () => {
        setSelectedFeedback(null);
    }

    const addFeedback = () => {
        // check license
        let isallowed = true;
        let license = auth.user.license;
        let current = feedbacks.length;
        if (license=="onebyone" && current>0) {
            isallowed = false;
        }
        if (license=="5" && current>4) {
            isallowed = false;
        }
        if (license=="10" && current>9) {
            isallowed = false;
        }
        if (license=="100" && current>99) {
            isallowed = false;
        }

        if (!isallowed) {
            alert(t('feedbacks.alert-lisence'));
        } else {
            // set user id if not allready
            if (!auth.user.og && !auth.user.rs) {
                defaultFeedback['userid'] = auth.user.id;
            }
            setSelectedFeedback(defaultFeedback);
        }
        
    }

    const loadFeedbacks = () => {
        setFeedbacks(null)
        let apitail = '?userid=';
        if ((auth.user && (auth.user.og || auth.user.rs)) && selectedUserId) {
            apitail = '?userid=' + selectedUserId;
        }
        axios.get(API + '/feedbacks/list'+apitail, {headers: { Authorization: `Bearer ${token}`} })
        .then(function (response) {
            if (response.data) {
                setFeedbacks(response.data.feedbacks)
                setSelectedFeedback(null)
            }
        })
        .catch(function(error) {
        });
    }
    
    const displayFeedback = (item, props) => {
        return (
        <button className={(selectedFeedback && selectedFeedback.id==item.id) ? "list-group-item list-group-item-action active" : "list-group-item list-group-item-action"} onClick={() => selectFeedback(item)}>
            <div className="ms-2 me-auto">
                <div className="fw-bold">{item.name}</div>
            </div>
        </button>
        );
    }

    const handleUserChange = (event) => {
        setSelectedUserId(event.target.value);
    }

    const loadUsers = () => {
        setUsers(null)
        axios.get(API + '/users/list', {headers: { Authorization: `Bearer ${token}`} })
        .then(function (response) {
            if (response.data) {
                setUsers(response.data.users)
            }
        })
        .catch(function(error) {
        });
    }

    useEffect(() => {
        // if masteruser load users too
        if (!users && (auth.user && (auth.user.og || auth.user.rs))) {
            loadUsers();
		}

		if (!feedbacks) {
            //loadFeedbacks();
		}
	}, [])

    useEffect(() => {
        clearFeedback();
        loadFeedbacks();
	}, [selectedUserId])

	return (
        <div className="container">

            {(users && auth.user && (auth.user.og || auth.user.rs)) &&
            <div className="row gx-5">
                <div className="col-12">
                    <div className="mb-4">
                        <label htmlFor="user" className="form-label">{t('adminapp.feedbacksuser-label')} *</label>
                        <select id="user" className="form-select" name="user" value={selectedUserId} onChange={handleUserChange}>
                            <option value="" default disabled hidden>{t('adminapp.feedbacksuser-default')}</option>
                        {users.map((user) =>
                            <option key={user.id} value={user.id}>{user.firstname} {user.lastname} {user.company ? ' / '+user.company : ''}</option>
                        )}
                        </select>
                    </div>

                </div>
            </div>
            }

            <div className="row gx-5">
                <div className="col-12 col-md-3">
                    <div className="d-grid gap-2 mb-4">
                        <button className="btn btn-primary" onClick={addFeedback}>{t('feedbacks.add')}</button>
                    </div>
                    {feedbacks &&
                        <PaginationList data={feedbacks} limit={30} search={true} itemrender={displayFeedback} itemaction={displayFeedback} searchkeys={['name']}/>
                    }
                </div>
                <div className="col-12 col-md-9 pt-3 pt-md-0">
                    <EditFeedback feedback={selectedFeedback} reloadFeedbacks={loadFeedbacks} clearFeedback={clearFeedback} selectedUserId={selectedUserId} />
                </div>
            </div>
        </div>
    );
}

export default Feedbacks;